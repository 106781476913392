import { createStore } from "vuex";
import works from '@/store/data.json'
import worksHome from '@/store/data_home.json'

export default createStore({
  state () {
    return {
      works: works,
      worksHome: worksHome,
      social: [
        {
          link:"mailto:work@devflow.ru",
          icon:"icon-mail",
          color:"#676CDB",
          name:"Почта"
        },
        {
          link:"https://t.me/pvrtdev",
          icon:"icon-tg",
          color:"#3390ec",
          name:"Telegram"
        },
        {
          link:"https://dribbble.com/pvrt",
          icon:"icon-drbl",
          color:"#ea4c89",
          name:"Dribbble"
        }
      ]
    }
  }
});