import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProjectsView from '../views/ProjectsView.vue';
import WorkView from '../views/WorkView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Главная | Веб-дизайн и разработка',
      metaTags: [
        {
          'http-equiv': 'Permissions-Policy',
          content: 'interest-cohort=()'
        },
        {
          name: 'description',
          content:
            'Привет 👋 Mеня зовут Павел, я UX/UI дизайнер и веб-разработчик. Люблю веб-разработку и чистый, лаконичный дизайн. Слежу за трендами и активно изучаю новые стандарты и технологии.'
        },
        {
          property: 'og:description',
          content:
            'Привет 👋 Mеня зовут Павел, я UX/UI дизайнер и веб-разработчик. Люблю веб-разработку и чистый, лаконичный дизайн. Слежу за трендами и активно изучаю новые стандарты и технологии.'
        }
      ]
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () =>
      import(
        /* webpackChunkName: "ProjectsView" */ '../views/ProjectsView.vue'
      ),
    meta: {
      title: 'Портфолио | Веб-дизайн и разработка',
      metaTags: [
        {
          'http-equiv': 'Permissions-Policy',
          content: 'interest-cohort=()'
        },
        {
          name: 'description',
          content:
            'Привет 👋 Mеня зовут Павел, я UX/UI дизайнер и веб-разработчик. Люблю веб-разработку и чистый, лаконичный дизайн. Слежу за трендами и активно изучаю новые стандарты и технологии.'
        },
        {
          property: 'og:description',
          content:
            'Привет 👋 Mеня зовут Павел, я UX/UI дизайнер и веб-разработчик. Люблю веб-разработку и чистый, лаконичный дизайн. Слежу за трендами и активно изучаю новые стандарты и технологии.'
        }
      ]
    }
  },
  {
    path: '/projects/:id',
    name: 'works',
    component: () =>
      import(/* webpackChunkName: "WorkView" */ '../views/WorkView.vue'),
    props: true,
    meta: {
      title: 'Портфолио | Веб-дизайн и разработка',
      metaTags: [
        {
          'http-equiv': 'Permissions-Policy',
          content: 'interest-cohort=()'
        }
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
