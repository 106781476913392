import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWriter from "vue-writer";
import VueLazyload from '@jambonn/vue-lazyload'
import VueTippy from 'vue-tippy'
import VueYandexMetrika from 'vue-yandex-metrika'

const yaMetrikaOptions = {
  id: 89956614,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
    trackHash:true
  }
}

createApp(App)
  .use(router)
  .use(store)
  .use(VueWriter)
  .use(VueLazyload)
  .use(VueTippy)
  .use(VueYandexMetrika, yaMetrikaOptions)
  .mount('#app')
